import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import i18n from 'i18next'
import React from 'react'
import { getCampaigns, addCampaign, updateCampaign } from '../service'
import actions from './actions'
import WarningMessage from '../components/WarningMessage'

export function* GET_CAMPAIGNS({ payload }) {
  yield put({
    type: 'campaigns/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const data = yield call(getCampaigns, payload)
  if (data.statusCode === 200) {
    const { campaigns } = data.data

    yield put({
      type: 'campaigns/SET_STATE',
      payload: {
        campaigns,
        pagination: {
          total: data.data.totalDocs,
          current: data.data.page,
          pageSize: data.data.limit,
        },
        loading: false,
        stats: data.data.campaignsStats,
      },
    })
  } else {
    yield put({
      type: 'campaigns/SET_STATE',
      payload: {
        campaigns: [],
        loading: false,
      },
    })
  }
}
export function* ADD_CAMPAIGN({ payload }) {
  yield put({
    type: 'campaigns/SET_STATE',
    payload: {
      loading: true,
      creating: true,
      selected: null,
    },
  })
  const response = yield call(addCampaign, payload)
  const { data } = response
  if (response.statusCode === 201) {
    yield put({
      type: 'campaigns/SET_STATE',
      payload: {
        loading: false,
        creating: false,
        selected: data,
      },
    })
    yield payload.history.push('/campaigns')
  } else if (response.status === 403) {
    yield put({
      type: 'campaigns/SET_STATE',
      payload: {
        loading: false,
        creating: false,
        selected: null,
      },
    })
    if (response.data.key === 'business_not_live') {
      notification.warning({
        description: i18n.t('campaigns:notification.businessDeactivated'),
        duration: 3,
      })
    }
    if (response.data.key === 'name_used') {
      notification.warning({
        description: i18n.t('campaigns:notification.campaignsNameUsed'),
        duration: 3,
      })
    }
    if (response.data.key === 'type_used') {
      notification.warning({
        description: i18n.t('campaigns:notification.campaignsTypeUsed'),
        duration: 3,
      })
    }
    if (response.data.key === 'push_notification_limit_reached') {
      notification.warning({
        message: i18n.t('campaigns:notification.watchIt'),
        description: <WarningMessage />,
        duration: 0,
      })
    }
  } else {
    yield put({
      type: 'campaigns/SET_STATE',
      payload: {
        loading: false,
        creating: false,
      },
    })
    notification.warning({
      description: i18n.t('campaigns:notification.addFalse'),
      duration: 3,
    })
    yield payload.history.push('/campaigns')
  }
}
export function* UPDATE_CAMPAIGN({ payload }) {
  yield put({
    type: 'campaigns/SET_STATE',
    payload: {
      loading: true,
      selected: null,
    },
  })
  const response = yield call(updateCampaign, payload)
  if (response.statusCode === 200) {
    yield put({
      type: 'campaigns/SET_STATE',
      payload: {
        loading: false,
      },
    })
    yield put({
      type: 'campaigns/GET_CAMPAIGNS',
      payload: {
        ...payload.apiParameters,
      },
    })
    notification.success({
      message: 200,
      description: i18n.t('campaigns:notification.updateTrue'),
      duration: 3,
    })
  } else {
    yield put({
      type: 'campaigns/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.warning({
      description: i18n.t('campaigns:notification.updateFalse'),
      duration: 3,
    })
  }
}
export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_CAMPAIGNS, GET_CAMPAIGNS),
    takeEvery(actions.ADD_CAMPAIGN, ADD_CAMPAIGN),
    takeEvery(actions.UPDATE_CAMPAIGN, UPDATE_CAMPAIGN),
  ])
}
